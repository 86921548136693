import { render, staticRenderFns } from "./ListRequests.vue?vue&type=template&id=6fa86f06&scoped=true"
import script from "./ListRequests.vue?vue&type=script&lang=js"
export * from "./ListRequests.vue?vue&type=script&lang=js"
import style0 from "./ListRequests.vue?vue&type=style&index=0&id=6fa86f06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa86f06",
  null
  
)

export default component.exports