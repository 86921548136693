<template>
  <aside class="section is-large">
    <h3>Esqueci minha senha</h3>

    <div class="field">
      <div class="control">
        <label for="email">Email</label>

        <input v-model="email" type="email" placeholder="email" class="input" />
      </div>
    </div>

    <div class="field">
      <div class="control">
        <button
          class="button is-primary"
          :class="{'is-loading': isLoading}"
          @click="sendResetEmail"
        >
          Enviar e-mail para recuperar a senha
        </button>
      </div>
    </div>

    <p class="has-text-danger" v-if="errorMessage">{{ errorMessage }}</p>

    <p class="has-text-success" v-if="successMessage">{{ successMessage }}</p>

    <div class="field">
      <div class="control">
        <button class="button is-link" @click="goToSignIn">
          Voltar para a página de Login
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import {auth} from "@/firecontainer";

export default {
  data() {
    return {
      email: "",
      isLoading: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    async sendResetEmail() {
      this.isLoading = true;
      this.errorMessage = "";
      this.successMessage = "";

      try {
        await auth.sendPasswordResetEmail(this.email);
        this.successMessage = "E-mail de confirmação enviado!";
      } catch (error) {
        console.error(error);
        this.errorMessage = error.message;
      }

      this.isLoading = false;
    },
    goToSignIn() {
      this.$router.push("/signin");
    },
  },
};
</script>
