<template>
  <section class="section">
    <h2>
      <router-link to="/">{{ this.$route.params.name }}</router-link> &gt; Histórico
    </h2>

    <ListHistory :communityId="this.$route.params.id" :history="history" :user="user" :deleteHistory="deleteHistory" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { db } from '@/firecontainer'
import ListHistory from './ListHistory'

export default {
  name: 'CommunityHistoryPage',
  components: { ListHistory },
  data() {
    return {
      history: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler: function () {
        this.fetchHistory()
      },
    },
  },
  methods: {
    async fetchHistory() {
      try {
        const communityId = this.$route.params.id

        // Check if the user is a superAdmin or an admin for the specific community
        if (this.user.data.superAdmin || (this.user.data.admin ?? []).includes(communityId)) {
          const querySnapshot = await db.collection('history').where('communityId', '==', communityId).get()

          this.history = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => {
              // Use approvedAt or rejectedAt for sorting
              const dateA = a.approvedAt?.toDate() || a.rejectedAt?.toDate() || a.createdAt.toDate()
              const dateB = b.approvedAt?.toDate() || b.rejectedAt?.toDate() || b.createdAt.toDate()
              return dateB - dateA // Most recent first
            })
        } else {
          console.warn('Not authorized to access this history')
          this.history = []
        }
      } catch (error) {
        console.error('Error fetching history', error)
        this.history = []
      }
    },

    async deleteHistory(request) {
      this.$confirm({
        message: 'Tem certeza que deseja deletar este item?',
        button: {
          no: 'NÃO',
          yes: 'SIM',
        },
        callback: async (confirm) => {
          if (confirm) {
            this.isLoading = true
            await this.clearHistory(request)
            await this.fetchHistory()
            this.isLoading = false
          }
        },
      })
    },
    async clearHistory(request) {
      try {
        console.log(`deleting: ${request.id} from database`)
        const res = await db.collection('history').doc(request.id).delete()
        console.log(res)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
