import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueConfirmDialog from "vue-confirm-dialog";

require("@/assets/main.scss");
import {auth} from "./firecontainer";

import App from "./App";
import Home from "./components/Home";
import UsersPage from "./components/users";
import CommunityPage from "./components/communities";
import CommunityAdsPage from "./components/communities/ads";
import CommunityRequestsPage from "./components/communities/requests";
import CommunityUsersPage from "./components/communities/users";
import CommunityHistoryPage from "./components/communities/history";
import RequestsPage from "./components/requests";
import ProfilesPage from "./components/profiles";
import HistoryPage from "@/components/history/index.vue";
import ForgotPassword from "./components/ForgotPassword";

import {firestorePlugin} from "vuefire";
import moment from "moment";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(firestorePlugin);
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.config.productionTip = false;

Vue.filter("formatDate", function (value) {
  if (value) return moment(value).format("LLL");
});

const store = new Vuex.Store({
  state: {
    loading: false,
    user: {
      loggedIn: false,
      data: null,
    },
  },
  getters: {
    isLoading(state) {
      return state.loading;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({commit}, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          superAdmin: user.superAdmin,
          admin: user.admin,
        });
      } else {
        commit("SET_USER", null);
      }
    },
  },
});

auth.onAuthStateChanged(async (user) => {
  if (user === null) {
    return store.dispatch("fetchUser", user);
  }

  const token = await user.getIdTokenResult();
  const claims = token.claims;

  store.dispatch("fetchUser", {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    superAdmin: claims.superAdmin ?? false,
    admin: claims.admin ?? [],
  });
});

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/communities",
      name: "Communities",
      component: CommunityPage,
      meta: {authenticationRequired: true},
    },
    {
      path: "/communities/:id/ads",
      name: "CommunityAds",
      component: CommunityAdsPage,
      meta: {authenticationRequired: true},
    },
    {
      path: "/communities/:id/requests",
      name: "CommunityRequests",
      component: CommunityRequestsPage,
      meta: {authenticationRequired: true},
    },
    {
      path: "/communities/:id/users",
      name: "CommunityUsers",
      component: CommunityUsersPage,
      meta: {authenticationRequired: true},
    },
    {
      path: "/communities/:id/history",
      name: "CommunityHistory",
      component: CommunityHistoryPage,
      meta: {authenticationRequired: true},
    },
    {
      path: "/users",
      name: "Users",
      component: UsersPage,
      meta: {
        authenticationRequired: true,
        superAdmin: true,
      },
    },
    {
      path: "/requests",
      name: "Requests",
      component: RequestsPage,
      meta: {
        authenticationRequired: true,
        superAdmin: true,
      },
    },
    {
      path: "/history",
      name: "History",
      component: HistoryPage,
    },
    {
      path: "/profiles",
      name: "Profiles",
      component: ProfilesPage,
      meta: {
        authenticationRequired: true,
        superAdmin: true,
      },
    },
    {
      path: "/forgot-password", // Add this route
      name: "ForgotPassword",
      component: ForgotPassword,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "ForgotPassword" &&
    ((to.matched.some((r) => r.meta.authenticationRequired) &&
      !store.getters.user.loggedIn) ||
      (to.matched.some((r) => r.meta.superAdmin) &&
        !store.getters.user.data.superAdmin))
  ) {
    next({path: "/"});
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
