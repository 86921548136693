import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// config prod

console.log("Environment:", process.env.VUE_APP_ENV);

const isTesting = process.env.VUE_APP_ENV === "testing";

const prodConfig = {
  apiKey: "AIzaSyBn-upnypsxsuWoC0F3EbNde-u5cuUOPVk",
  authDomain: "escambo-app-5a9c5.firebaseapp.com",
  projectId: "escambo-app-5a9c5",
  storageBucket: "escambo-app-5a9c5.appspot.com",
  messagingSenderId: "1040488810547",
  appId: "1:1040488810547:web:0f5726fece62ca99a53b46",
  measurementId: "G-N62CZ6G1VK",
};

const testConfig = {
  apiKey: "AIzaSyAAWn5yKasCsyAI5HM1W3oOxxIkws9zDLo",
  authDomain: "teste-escambo.firebaseapp.com",
  projectId: "teste-escambo",
  storageBucket: "teste-escambo.appspot.com",
  messagingSenderId: "352433312375",
  appId: "1:352433312375:web:9489272464a4bea8556c72",
  measurementId: "G-J4PEW0W4PV",
};

const firebaseConfig = isTesting ? testConfig : prodConfig;

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export const FunctionUsersAddSuperAdminRole = "users-addSuperAdminRole";
export const FunctionUsersAddAdminRole = "users-addAdminRole";
export const FunctionUsersAddMemberRole = "users-addMemberRole";
export const FunctionUsersRemoveMemberRole = "users-removeMemberRole";
export const FunctionUsersInspectUser = "users-inspectUser";

const {Timestamp, GeoPoint, FieldPath, FieldValue} = firebase.firestore;
export {Timestamp, GeoPoint, FieldPath, FieldValue};
