<template>
  <section class="section">
    <h3>Adicionar Membro</h3>

    <label for="userId">ID do Usuário</label>
    <input v-model="userId" type="text" placeholder="ID do Usuário" class="input" />

    <label for="communityId">ID da Comunidade</label>
    <input v-model="communityId" type="text" placeholder="ID da Comunidade" class="input" />

    <button
      class="button is-danger"
      :class="{ 'is-loading': isLoading }"
      @click="$emit('make-member', userId, communityId)"
    >
      ADICIONAR MEMBRO
    </button>
  </section>
</template>

<script>
export default {
  props: ['isLoading'],
  data() {
    return {
      userId: '',
      communityId: '',
    }
  },
}
</script>
