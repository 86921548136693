<template>
  <section class="section">
    <h3>Adicionar Super Admin</h3>

    <label for="userId">ID do Usuário</label>
    <input v-model="userId" type="text" placeholder="ID do Usuário" class="input" />

    <button class="button is-danger" :class="{ 'is-loading': isLoading }" @click="$emit('make-super-admin', userId)">
      TRANSFORMAR EM SUPER ADMIN
    </button>
  </section>
</template>

<script>
export default {
  props: ['isLoading'],
  data() {
    return {
      userId: '',
    }
  },
}
</script>
