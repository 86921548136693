import {parsePhoneNumberFromString} from "libphonenumber-js";

export function formatPhoneNumber(phone, countryCode = "BR") {
  if (!phone) return "Não informado";

  const phoneNumber = parsePhoneNumberFromString(phone, countryCode || "BR");
  if (!phoneNumber) return "Não informado";

  // Get the national format and add the parentheses correctly
  const nationalNumber = phoneNumber.format("NATIONAL"); // Example: "11 94498-7779"
  const formattedNational = nationalNumber.replace(/^(\d{2}) /, "($1) "); // Add parentheses around the area code

  return `+${phoneNumber.countryCallingCode} ${formattedNational}`;
}
