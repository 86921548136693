<template>

  <div id="app" class="content">

    <NavBar :isLoggedIn="user.loggedIn" />

    <vue-confirm-dialog></vue-confirm-dialog>

    <div class="container">

      <!-- If the user is logged in or the current route is 'ForgotPassword', show the router-view -->

      <router-view
        v-if="user.loggedIn || $route.name === 'ForgotPassword'"
      ></router-view>

      <!-- Otherwise, show the SignIn component -->

      <SignIn v-else-if="$route.name !== 'ForgotPassword'" />

    </div>

    <Footer />

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import NavBar from "./components/core/NavBar";
import Footer from "./components/core/Footer";
import SignIn from "./components/SignIn";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    SignIn,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

