<template>
  <section class="section">
    <h2>Novo Anúncio</h2>

    <form @submit.prevent class="form">
      <div class="field">
        <label for="title">Título</label>
        <div class="control">
          <input v-model="title" type="text" id="title" class="input" />
        </div>
      </div>

      <div class="field">
        <label for="description">Descrição</label>
        <div class="control">
          <textarea v-model="description" type="text" id="description" class="textarea" />
        </div>
      </div>

      <div class="field">
        <label for="price">Preço</label>
        <div class="control">
          <input v-model="price" type="text" id="price" class="input" />
        </div>
      </div>

      <div class="field">
        <label for="expiresAt">Expira em</label>
        <div class="control">
          <input v-model="expiresAt" type="text" placeholder="2021-03-01" id="expiresAt" class="input" />
        </div>
      </div>

      <!-- <div class="field is-grouped">
        <label for="latitude">Latitude</label>
        <div class="control">
          <input v-model="latitude" type="text" id="latitude" class="input" />
        </div>

        <label for="longitude">Longitude</label>
        <div class="control">
          <input v-model="longitude" type="text" id="longitude" class="input" />
        </div>
      </div> -->

      <div class="control">
        <button @click="addAd()" class="button is-primary">ADICIONAR</button>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      title: `Nome ${Date.now()}`,
      description: 'Descrição, lorem ipsum',
      price: 199,
      images: [],
      expiresAt: null,
    }
  },
  methods: {
    addAd() {
      const ad = {
        title: this.title,
        description: this.description,
        price: this.price,
        images: this.images,
        expiresAt: new Date(this.expiresAt),
      }

      this.$emit('add-ad', ad)
    },
  },
}
</script>
