<template>
  <aside class="section is-large">
    <h3>Login</h3>

    <div class="field">
      <div class="control">
        <label for="email">Email</label>

        <input v-model="email" type="email" placeholder="email" class="input" />
      </div>
    </div>

    <div class="field">
      <div class="control">
        <label for="Password">Senha</label>

        <input
          v-model="password"
          type="password"
          placeholder="senha"
          class="input"
        />
      </div>
    </div>

    <div class="field">
      <div class="control">
        <button
          class="button is-primary"
          :class="{'is-loading': isLoading}"
          @click="signInOrCreateUser()"
        >
          ENTRAR
        </button>
      </div>
    </div>

    <p class="has-text-danger" v-if="errorMessage">{{ errorMessage }}</p>

    <div class="field">
      <div class="control">
        <button class="button is-primary" @click="goToForgotPassword">
          Esqueci minha senha
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import {auth} from "@/firecontainer";

export default {
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      errorMessage: "",
    };
  },

  methods: {
    async signInOrCreateUser() {
      this.isLoading = true;
      this.errorMessage = "";

      try {
        await auth.signInWithEmailAndPassword(this.email, this.password);
      } catch (error) {
        console.error(error);
        this.errorMessage = error.message;
      }

      this.isLoading = false;
    },
    goToForgotPassword() {
      this.$router.push("/forgot-password");
    },
  },
};
</script>
