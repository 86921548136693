<template>
  <section class="section">
    <h2>
      <router-link to="/">{{ this.$route.params.name }}</router-link>
      &gt; Usuários
    </h2>

    <ListUsers :communityId="this.$route.params.id" :profiles="profiles" @remove-user="removeUser" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, functions, FunctionUsersRemoveMemberRole, FieldPath, FieldValue } from '@/firecontainer'
import ListUsers from './ListUsers'

export default {
  name: 'CommunityUsersPage',
  components: { ListUsers },
  data() {
    return {
      profiles: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler: function () {
        if (this.$route.params.id.length > 0) {
          const communityId = this.$route.params.id
          this.fetchCommunityMembers(communityId)
        } else {
          console.log('No community')
        }
      },
    },
  },
  methods: {
    async fetchCommunityMembers(communityId) {
      console.log(this.user.data.admin)
      console.log(this.$route.params.id)
      if (this.user.data.superAdmin || (this.user.data.admin ?? []).includes(this.$route.params.id)) {
        const communitiesSnapshot = await db.collection('communities').doc(communityId).get()

        if (communitiesSnapshot.exists) {
          console.log('Community exists')
          const communityData = communitiesSnapshot.data()
          this.profiles = await this.fetchProfiles(communityData.members)
        } else {
          console.log('Community profiles doesnt exists')
        }
      }
    },
    async fetchProfiles(members) {
      console.log('Start searching for members')
      /*const profilesSnapshot = await db
      .collection('profiles')
      .where(FieldPath.documentId(), 'in', members)
      .get()

      return profilesSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })*/
      // Fetch profiles using getContentById method
      const profiles = await this.getContentById(members, 'profiles')

      // Sort profiles alphabetically by displayName
      return profiles.sort((a, b) => {
        const nameA = (a.displayName || a.email || '').toLowerCase()
        const nameB = (b.displayName || b.email || '').toLowerCase()
        return nameA.localeCompare(nameB)
      })
    },
    async removeUser(user) {
      console.log('Received user object:', user)
      console.log(`user is ${user.id}`)
      this.$confirm({
        message: 'Tem certeza que deseja remover esse usuário?',
        button: {
          no: 'NÃO',
          yes: 'SIM',
        },
        callback: async (confirm) => {
          if (confirm) {
            this.isLoading = true
            const communityId = this.$route.params.id
            const removeMemberRoleFunction = functions.httpsCallable(FunctionUsersRemoveMemberRole)
            await removeMemberRoleFunction({
              userId: user.id,
              communityId: communityId,
            })
            await this.removeUserFromCommunity(user.id, communityId)
            console.log('user removed')
            this.isLoading = false
          }
        },
      })
    },
    async removeUserFromCommunity(userId, communityId) {
      try {
        const communityRef = db.collection('communities').doc(communityId)
        await communityRef.update({
          members: FieldValue.arrayRemove(userId),
        })
        console.log('user removed from community')

        this.profiles = this.profiles.filter((profile) => profile.id !== userId)
      } catch (error) {
        console.error('Error removing user from community: ', error)
      }
    },
    async getContentById(ids, path) {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length || !path) return []

      const collectionPath = db.collection(path)
      const batches = []

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10)

        // add the batch request to to a queue
        batches.push(
          collectionPath
            .where(FieldPath.documentId(), 'in', [...batch])
            .get()
            .then((results) =>
              results.docs.map((result) => ({
                id: result.id,
                ...result.data(),
              }))
            )
        )
      }

      // after all of the data is fetched, return it
      return Promise.all(batches).then((content) => content.flat())
    },
  },
}
</script>
