<template>
  <section class="section">
    <h2>Nova Comunidade</h2>

    <form @submit.prevent class="form">
      <div class="field">
        <label for="name">Nome</label>
        <div class="control">
          <input v-model="name" type="text" id="name" class="input" />
        </div>
      </div>

      <div class="field">
        <label for="description">Descrição</label>
        <div class="control">
          <textarea v-model="description" type="text" id="description" class="textarea" />
        </div>
      </div>

      <div class="field">
        <label for="address">Endereço</label>
        <div class="control">
          <input v-model="address" type="text" id="address" class="input" />
        </div>
      </div>

      <!-- <div class="field is-grouped">
        <label for="latitude">Latitude</label>
        <div class="control">
          <input v-model="latitude" type="text" id="latitude" class="input" />
        </div>

        <label for="longitude">Longitude</label>
        <div class="control">
          <input v-model="longitude" type="text" id="longitude" class="input" />
        </div>
      </div> -->

      <div class="control">
        <button @click="addCommunity()" class="button is-primary">ADICIONAR</button>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: `Nome ${Date.now()}`,
      description: 'Descrição, lorem ipsum',
      address: `Av. dos Estados, ${Date.now()}`,
      location: { latitude: -23.123, longitude: -46.321 },
    }
  },
  methods: {
    addCommunity() {
      const community = {
        name: this.name,
        description: this.description,
        address: this.address,
        location: this.location,
      }

      this.$emit('add-community', community)
    },
  },
}
</script>
