<template>
  <section class="section">
    <h2>Nova Requisição</h2>

    <form @submit.prevent class="form">
      <div class="field">
        <label for="user">ID do Usuário</label>
        <div class="control">
          <input v-model="userId" type="text" id="user" class="input" />
        </div>
      </div>

      <div class="field">
        <label for="community">ID da Comunidade</label>
        <div class="control">
          <input v-model="communityId" type="text" id="community" class="input" />
        </div>
      </div>

      <div class="control">
        <button @click="addRequest()" class="button is-primary">ADICIONAR</button>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      userId: '',
      communityId: '',
    }
  },
  methods: {
    addRequest() {
      const request = {
        userId: this.userId,
        communityId: this.communityId,
      }

      this.$emit('add-request', request)
    },
  },
}
</script>
