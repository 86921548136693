<template>
  <section class="section">
    <h2>Usuários</h2>
    <UserInfo v-if="user.data.superAdmin" />

    <InspectUser :isLoading="isLoading" :claims="userClaims" @inspect-user="inspectUser" />
    <AddSuperAdmin :isLoading="isLoading" @make-super-admin="addSuperAdminRole" />
    <AddAdmin :isLoading="isLoading" @make-admin="addAdminRole" />
    <AddMember :isLoading="isLoading" @make-member="addMemberRole" />
    <RemoveMember :isLoading="isLoading" @remove-member="removeMemberRole" />
  </section>
</template>

<script>
import {
  functions,
  db,
  FunctionUsersAddSuperAdminRole,
  FunctionUsersAddAdminRole,
  FunctionUsersAddMemberRole,
  FunctionUsersRemoveMemberRole,
  FunctionUsersInspectUser,
} from '@/firecontainer'
import { mapGetters } from 'vuex'
import UserInfo from '../core/UserInfo'
import AddSuperAdmin from './AddSuperAdmin'
import AddAdmin from './AddAdmin'
import AddMember from './AddMember'
import RemoveMember from './RemoveMember'
import InspectUser from './InspectUser'

export default {
  name: 'UsersPage',
  components: { UserInfo, AddSuperAdmin, AddAdmin, AddMember, RemoveMember, InspectUser },
  data() {
    return {
      isLoading: false,
      userClaims: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  methods: {
    async addSuperAdminRole(userId) {
      this.isLoading = true
      console.log(`adding super admin: ${userId}`)

      const addSuperAdminRoleFunction = functions.httpsCallable(FunctionUsersAddSuperAdminRole)
      const result = await addSuperAdminRoleFunction({
        userId: userId,
      })

      console.log(result.data.message)
      this.isLoading = false
    },

    async addAdminRole(userId, communityId) {
      this.isLoading = true
      console.log(`adding ${userId} as admin of ${communityId}`)

      const addAdminRoleFunction = functions.httpsCallable(FunctionUsersAddAdminRole)
      const result = await addAdminRoleFunction({
        userId: userId,
        communityId: communityId,
      })

      console.log(result.data.message)
      this.isLoading = false
    },

    async addMemberRole(userId, communityId) {
      this.isLoading = true
      console.log(`adding ${userId} as member of ${communityId}`)
      await this.addMemberToCommunity(userId, communityId)

      const addMemberRoleFunction = functions.httpsCallable(FunctionUsersAddMemberRole)
      const result = await addMemberRoleFunction({
        userId: userId,
        communityId: communityId,
      })

      console.log(result.data.message)
      this.isLoading = false
    },

    async addMemberToCommunity(memberId, communityId) {
      const communityRef = db.collection('communities').doc(communityId)
      const profileIdToAdd = memberId

      const communityDoc = await communityRef.get()
      const membersArray = communityDoc.data().members || []

      if (!membersArray.includes(profileIdToAdd)) {
        membersArray.push(profileIdToAdd)

        await communityRef.update({ members: membersArray })

        console.log(`Adicionado ${profileIdToAdd} como membro de ${communityId}`)
      } else {
        console.log(`${profileIdToAdd} já é membro de ${communityId}`)
      }
    },

    async removeMemberRole(userId, communityId) {
      this.isLoading = true
      console.log(`removing ${userId} as member of ${communityId}`)

      const removeMemberRoleFunction = functions.httpsCallable(FunctionUsersRemoveMemberRole)
      const result = await removeMemberRoleFunction({
        userId: userId,
        communityId: communityId,
      })

      console.log(result.data.message)
      this.isLoading = false
    },

    async inspectUser(userId) {
      this.isLoading = true
      console.log(`fetching claims for ${userId}`)

      const inspectUserFunction = functions.httpsCallable(FunctionUsersInspectUser)
      const result = await inspectUserFunction({
        userId: userId,
      })

      console.log(result.data.message)
      this.userClaims = result.data.message.userClaims
      this.isLoading = false
    },
  },
}
</script>
