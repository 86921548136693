<template>
  <section class="section">
    <h3>Inspecionar Usuário</h3>

    <label for="userId">ID do Usuário</label>
    <input v-model="userId" type="text" placeholder="ID do Usuário" class="input" />

    <button class="button is-danger" :class="{ 'is-loading': isLoading }" @click="$emit('inspect-user', userId)">
      BUSCAR
    </button>

    <p>Claims:
      <ul>
        <li v-for="(v, k) in claims" :key="k">
          {{ k }}: {{ v }}
        </li>
      </ul>
    </p>
  </section>
</template>

<script>
export default {
  props: ['isLoading', 'claims'],
  data() {
    return {
      userId: '',
    }
  },
}
</script>
