<template>
  <section class="section">
    <h2>Histórico</h2>

    <div class="tile is-ancestor is-vertical">
      <div v-if="!history.length" class="card">
        <div class="card-content">
          <div class="content">Nenhum histórico disponível.</div>
        </div>
      </div>

      <div v-for="item in history" :key="item.id" class="card mb-4">
        <div class="card-content">
          <p>
            <span class="title">Comunidade: {{ item.communityName }}</span
            ><br />
            <span class="subtitle">ID da solicitação: {{ item.id }}</span>
          </p>
          <div class="columns is-mobile is-multiline is-vcentered">
            <!-- Image -->
            <div class="column is-narrow-desktop is-12-mobile profile-image-container is-flex is-align-items-center">
              <figure class="image is-128x128">
                <img
                  :src="item?.userPhotoURL || fallbackAvatar"
                  alt="User avatar"
                  @error="onImageError($event)"
                  class="profile-image is-rounded"
                />
              </figure>
            </div>

            <!-- Details -->
            <div class="column">
              <div class="profile-details">
                <p class="subtitle is-6 mb-2">
                  <strong>Usuário:</strong> {{ item.userDisplayName || 'Não informado' }}
                </p>
                <p class="subtitle is-6 mb-2"><strong>Email:</strong> {{ item.userEmail || 'Não informado' }}</p>
                <p class="subtitle is-6 mb-2">
                  <strong>Telefone:</strong> {{ formatPhoneNumber(item.userContactInfo, 'BR' || 'Não informado') }}
                </p>

                <p class="subtitle is-6 mb-2">
                  <strong>Solicitação criada em:</strong> {{ item.createdAt.toDate() | formatDate }}
                </p>
                <p v-if="item.wasApproved" class="subtitle is-6 has-text-success">
                  <strong>Solicitação aprovada em:</strong> {{ item.approvedAt.toDate() | formatDate }}
                </p>
                <p v-else class="subtitle is-6 has-text-danger">
                  <strong>Solicitação rejeitada em:</strong> {{ item.rejectedAt.toDate() | formatDate }}
                </p>
              </div>

              <!-- Action Buttons -->
              <div v-if="user?.data?.superAdmin" class="buttons is-right mt-4">
                <button class="button is-danger is-outlined" @click="deleteHistory(item)">DELETAR</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatPhoneNumber } from '@/utils/phoneUtils'

export default {
  props: ['history', 'user', 'deleteHistory'],
  data() {
    return {
      fallbackAvatar: require('@/assets/avatar.png'), // Replace with the path to your fallback image
    }
  },

  methods: {
    onImageError(event) {
      event.target.src = this.fallbackAvatar // Replace broken image with fallback
    },
    formatPhoneNumber,
  },
}
</script>

<style scoped>
.profile-image-container {
  display: flex; /* Enable flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Take up full height of the card */
}

.profile-image {
  object-fit: cover;
  width: 128px;
  height: 128px;
}
</style>
