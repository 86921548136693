<template>
  <section class="section">
    <h2>Requisições Pendentes</h2>

    <div class="tile is-ancestor is-vertical">
      <div v-if="!requests.length" class="card">
        <div class="card-content">
          <div class="content">Nenhuma solicitação pendente. Parabéns.</div>
        </div>
      </div>

      <div v-for="request in requests" :key="request.id" class="card mb-4">
        <div class="card-content">
          <p>
            <span class="title" v-if="request.communityData && request.communityData.name"
              >Comunidade: {{ request.communityData.name }}</span
            ><br />
            <span class="subtitle">ID da Comunidade: {{ request.communityId }}</span>
          </p>
          <div class="tags">
            <time v-if="request.createdAt" class="tag">{{ request.createdAt.toDate() | formatDate }}</time>
          </div>
          <div class="columns is-mobile is-multiline is-vcentered">
            <!-- Image -->
            <div class="column is-narrow-desktop is-12-mobile profile-image-container is-flex is-align-items-center">
              <figure class="image is-128x128">
                <img
                  :src="request.userProfile?.userPhotoURL || fallbackAvatar"
                  alt="User avatar"
                  @error="onImageError($event)"
                  class="profile-image is-rounded"
                />
              </figure>
            </div>

            <!-- Details -->
            <div class="column">
              <div class="profile-details">
                <p class="subtitle is-6 mb-2"><strong>ID do Usuário:</strong> {{ request.userId }}</p>
                <p class="subtitle is-6 mb-2" v-if="request.userProfile && request.userProfile.displayName">
                  <strong>Usuário:</strong> {{ request.userProfile.displayName }}
                </p>
                <p class="subtitle is-6 mb-2" v-if="request.userProfile && request.userProfile.email">
                  <strong>Email:</strong> {{ request.userProfile.email }}
                </p>
                <p class="subtitle is-6 mb-2" v-if="request.userProfile && request.userProfile.contactInfo">
                  <strong>Telefone:</strong> {{ formatPhoneNumber(request.userProfile.contactInfo, 'BR') }}
                </p>
              </div>

              <!-- Action Buttons -->
              <div class="buttons is-right mt-4">
                <button @click="$emit('reject-request', request)" class="button is-danger is-outlined">RECUSAR</button>
                <button @click="$emit('approve-request', request)" class="button is-primary is-outlined">
                  ACEITAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatPhoneNumber } from '@/utils/phoneUtils'

export default {
  props: ['requests'],
  data() {
    return {
      fallbackAvatar: require('@/assets/avatar.png'), // Replace with the path to your fallback image
    }
  },

  methods: {
    onImageError(event) {
      event.target.src = this.fallbackAvatar // Replace broken image with fallback
    },
    formatPhoneNumber,
  },
}
</script>

<style scoped>
.profile-image-container {
  display: flex; /* Enable flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Take up full height of the card */
}

.profile-image {
  object-fit: cover;
  width: 128px;
  height: 128px;
}

.profile-details {
  margin-top: 1rem;
}
</style>
