<template>
  <section class="section">
    <h3>Inspecionar Perfil</h3>

    <label for="userId">ID do Usuário</label>
    <input v-model="userId" type="text" placeholder="ID do Usuário" class="input" />

    <div class="buttons">
      <button class="button is-primary" :class="{ 'is-loading': isLoading }" @click="$emit('inspect-profile', userId)">
        BUSCAR
      </button>

      <button class="button is-warning" :class="{ 'is-loading': isLoading }" @click="$emit('update-profile', userId)">
        RESET
      </button>

      <button class="button is-danger" :class="{ 'is-loading': isLoading }" @click="$emit('delete-profile', userId)">
        DELETE
      </button>
    </div>

    <p>Data:
      <ul>
        <li v-for="(v, k) in profileData" :key="k">
          {{ k }}: {{ v }}
        </li>
      </ul>
    </p>
  </section>
</template>

<script>
export default {
  props: ['isLoading', 'profileData'],
  data() {
    return {
      userId: '',
    }
  },
}
</script>
