<template>
  <section class="section">
    <h2>
      <router-link to="/">{{ this.$route.params.name }}</router-link> &gt; Anúncios
    </h2>

    <AddAd v-if="user.data.superAdmin" @add-ad="createAd" />

    <ListAds :communityId="this.$route.params.id" :ads="ads" @delete-ad="deleteAd" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, Timestamp, serverTimestamp } from '@/firecontainer'
import AddAd from './AddAd'
import ListAds from './ListAds'

export default {
  name: 'CommunityAdsPage',
  components: { AddAd, ListAds },
  data() {
    return {
      ads: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  firestore() {
    return {
      ads: db.collection('communities').doc(this.$route.params.id).collection('ads').orderBy('createdAt', 'desc'),
    }
  },
  methods: {
    async createAd(ad) {
      const userId = this.$store.getters.user.data.uid
      const newAd = {
        title: ad.title,
        description: ad.description,
        price: ad.price,
        images: [],
        expiresAt: ad.expiresAt ? Timestamp.fromDate(ad.expiresAt) : null,
        createdAt: serverTimestamp(),
        owner: userId,
      }

      await db.collection('communities').doc(this.$route.params.id).collection('ads').add(newAd)
    },

    async deleteAd(adId) {
      this.$confirm({
        message: 'Tem certeza que deseja deletar esse anúncio? Esta operação não pode ser desfeita',
        button: {
          no: 'NÃO',
          yes: 'SIM',
        },
        callback: async (confirm) => {
          if (confirm) {
            // TODO: implement loading
            this.isLoading = true
            await db.collection('communities').doc(this.$route.params.id).collection('ads').doc(adId).delete()
            this.isLoading = false
          }
        },
      })
    },
  },
}
</script>
