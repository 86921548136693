<template>
  <div class="profile-cards">
    <!-- Empty State -->
    <div v-if="!profiles.length" class="card has-text-centered">
      <div class="card-content">
        <p class="subtitle">Nenhum usuário vinculado.</p>
      </div>
    </div>

    <!-- Profile Cards -->
    <ProfileCard
      v-for="profile in profiles"
      :key="profile.email"
      :image="profile.photoURL"
      :title="profile.displayName"
      :id="profile.id"
    >
      <template #details>
        <p class="subtitle is-6 mb-2"><strong>Nome:</strong> {{ profile.displayName || 'Not provided' }}</p>
        <p class="subtitle is-6 mb-2"><strong>ID:</strong> {{ profile.id || 'Not provided' }}</p>
        <p class="subtitle is-6 mb-2"><strong>Email:</strong> {{ profile.email || 'Não informado' }}</p>
        <p class="subtitle is-6 mb-2">
          <strong>Telefone:</strong>
          {{ formatPhoneNumber(profile.contactInfo, profile.countryCode || 'BR') || 'Não informado' }}
        </p>
        <p class="subtitle is-6 mb-2">
          <strong>Complemento:</strong> {{ profile.addressComplement || 'Não informado' }}
        </p>
      </template>
      <template #actions>
        <button @click="$emit('remove-user', profile)" class="button is-danger is-outlined is-small">REMOVER</button>
      </template>
    </ProfileCard>
  </div>
</template>

<script>
import { formatPhoneNumber } from '@/utils/phoneUtils'
import ProfileCard from '@/components/widgets/ProfileCard.vue'

export default {
  name: 'ListUsers', // Changed from 'ProfileCard'
  components: {
    ProfileCard,
  },
  props: {
    profiles: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatPhoneNumber,
  },
}
</script>
