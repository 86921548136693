<template>
  <div class="card profile-card mb-4">
    <div class="card-content">
      <div class="columns is-mobile is-multiline is-centered">
        <!-- Image -->
        <div class="column is-narrow-desktop is-12-mobile profile-image-container">
          <figure class="image is-128x128">
            <img
              :src="image || fallbackAvatar"
              alt="User avatar"
              @error="onImageError($event)"
              class="profile-image is-rounded"
            />
          </figure>
        </div>

        <!-- Details -->
        <div class="column">
          <div class="profile-details">
            <slot name="details">
              <p class="subtitle is-6 mb-2"><strong>Name:</strong> {{ title || 'Not provided' }}</p>
              <p class="subtitle is-6 mb-2"><strong>ID:</strong> {{ id || 'Not provided' }}</p>
            </slot>
          </div>

          <!-- Action Buttons -->
          <div class="buttons is-right mt-4">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileCard',
  props: {
    image: String,
    title: String,
    id: String,
  },
  data() {
    return {
      fallbackAvatar: require('@/assets/avatar.png'),
    }
  },
  methods: {
    onImageError(event) {
      event.target.src = this.fallbackAvatar
    },
  },
}
</script>

<style scoped>
.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  object-fit: cover;
  width: 128px;
  height: 128px;
}
</style>
