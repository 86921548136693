<template>
  <div>
    <p>Signed In as: {{ user.uid }}</p>
    <p>Claims:
      <ul>
        <li v-for="(v, k) in claims" :key="k">
          {{ k }}: {{ v }}
        </li>
      </ul>
    </p>

    <button
      class="button"
      :class="{ 'is-loading': isLoading }"
      @click="getClaims()">
        Refresh Claims
    </button>

    <button class="button" @click="signOut()">Sign Out</button>
  </div>
</template>

<script>
import { auth } from '@/firecontainer'

export default {
  data() {
    return {
      claims: {},
      isLoading: false,
    }
  },
  props: ['user'],
  methods: {
    async getClaims() {
      this.isLoading = true

      const token = await auth.currentUser.getIdTokenResult()
      this.claims = token.claims

      this.isLoading = false
    },

    async signOut() {
      auth.signOut()
    },
  },
}
</script>
