<template>
  <section class="section">
    <UserInfo />

    <InspectProfile
      :profileData="profileData"
      @inspect-profile="inspectProfile"
      @update-profile="updateProfile"
      @delete-profile="deleteProfile"
    />
  </section>
</template>

<script>
import { db, serverTimestamp } from '@/firecontainer'
import UserInfo from '../core/UserInfo.vue'
import InspectProfile from './InspectProfile.vue'

export default {
  name: 'ProfilesPage',
  components: { UserInfo, InspectProfile },
  data() {
    return {
      isLoading: false,
      profileData: {},
      profiles: [],
    }
  },
  firestore() {
    return {
      profiles: db.collection('profiles').orderBy('createdAt', 'desc'),
    }
  },
  methods: {
    async inspectProfile(userId) {
      this.isLoading = true
      console.log(`fetching profile for ${userId}`)

      const profileDocRef = await db.collection('profiles').doc(userId).get()
      this.profileData = profileDocRef.data()
      console.log(profileDocRef.data())

      this.isLoading = false
    },

    async updateProfile(userId) {
      this.isLoading = true
      console.log(`updating profile for ${userId}`)

      const newProfile = {
        displayName: this.profileData?.displayName ?? 'Escambo Teste',
        email: this.profileData?.email ?? 'default@esc.bo',
        photoURL:
          this.profileData?.photoURL ??
          'https://assets.coingecko.com/coins/images/16352/large/Stonks200x200.png?1623805286',
        contactInfo: this.profileData?.contactInfo ?? '5511998484338',
        favorites: this.profileData?.favorites ?? [],
        createdAt: this.profileData?.createdAt ?? serverTimestamp(),
        updatedAt: serverTimestamp(),
      }
      console.log(newProfile)
      console.log(newProfile.contactInfo)

      const profileDocRef = await db.collection('profiles').doc(userId).set(newProfile)
      console.log(profileDocRef)

      this.isLoading = false
    },

    async deleteProfile(userId) {
      this.isLoading = true
      console.log(`deleting profile for ${userId}`)

      const profileDocRef = await db.collection('profiles').doc(userId).delete()
      console.log(profileDocRef)

      this.isLoading = false
    },
  },
}
</script>
